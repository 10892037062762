import {
    AppointmentStatus,
    CalculatedAppointmentStatus,
    EHRSystem,
} from '@docpace/shared-ts-types'
import {
    TranslationContentFragment,
    AppointmentEstimateFragment,
    ProviderDepartmentOptionCompiledFragment,
    StatsCompiledFragment,
    MlPredictedDurationsFragment,
    AppointmentCycleConfigCompiledFragment,
    AppointmentTextFragment,
} from '@docpace/shared-graphql/fragments'

export const appointmentEndTimeKeys = [
    '_timeCheckOutStart',
    '_timeCheckOutEnd',
    '_timeEncounterCheckedOut',
]

export type DelayTimestampsAppointment = { 
    appointmentId: string
    providerId?: string
    practiceId?: string
    appointmentTypeId?: string
    patientId?: string
    urgent?: boolean
    appointmentEstimate?: AppointmentEstimateFragment | null
    providerDepartmentOptionCompiled?: ProviderDepartmentOptionCompiledFragment | null
    srcType: string | EHRSystem
    statsCompiled?: StatsCompiledFragment | null
    appointmentPredictedDuration?: MlPredictedDurationsFragment | null
    appointmentCycleConfigCompiled?: AppointmentCycleConfigCompiledFragment | null
    appointmentTexts: { nodes: (AppointmentTextFragment | null)[] } | null
    timeScheduled: string,
    timeCreated?: string | null
    timeUpdated?: string | null
    created?: string | null
    updated?: string | null
    pausedAt?: string | null
    status: string | AppointmentStatus
    calculatedAppointmentStatus?: CalculatedAppointmentStatus
    _timeCheckInStart?: string | null
    _timeCheckInEnd?: string | null
    _timeIntakeStart?: string | null
    _timeIntakeEnd?: string | null
    _timeExamStart?: string | null
    _timeExamEnd?: string | null
    _timeCheckOutStart?: string | null
    _timeCheckOutEnd?: string | null
    timeEncounterCheckedOut?: string | null
    patientManualCheckIn?: string | null
    rescheduledAppointmentId?: string | null
}

export type SsrAppointment = {
    appointmentId?: string
    practiceId?: string
    athenaPracticeId?: string
    patientManualCheckIn?: string
    departmentId?: string
    providerId?: string
    timeScheduled?: string
    suggestedArrivalTime?: string
    providerDisplayName?: string
    languages?: string[]
    practiceName?: string
    srcType?: string
    status?: string
    timePatientSiteFirstVisited?: string
    timePatientSiteIntakeFormVisited?: string
    timePatientClickedIntakeLink?: string
    countDobAuthenticationRequests?: string
    patientSiteShowCallButton?: boolean
    patientSiteShowIntakeForm?: boolean
    patientSiteShowLocation?: boolean
    showCheckInButtonInVwr?: boolean
    showSuggestedArrivalTimeInVwr?: boolean
    translationContents?: TranslationContentFragment[]
    translatedTranslationContents?: TranslationContentFragment[]
    patientSiteContactTranslationId?: string
    patientSiteDirectionsTranslationId?: string
    patientSiteRemoteDirectionsTranslationId?: string
    patientSiteFaqTranslationId?: string
    patientSiteIntakeFormUrlTranslationId?: string
    googlePlaceId?: string
    googlePlaceData?: any
    googlePlacePhoneNumber?: string
    deptAddress?: string
    deptPhoneNumber?: string
    patientSiteDisplayPaymentPortal?: boolean
    patientSiteDisplayPatientPortal?: boolean
    patientSiteDisplayLatestSmsSuggestedArrivalTime?: boolean
    patientSitePracticeSlugId?: number
    timeLatestTextedSuggestedArrivalTime?: string
    remote?: boolean
    patientSiteAboutVideoLinkTranslationId?: string
    currentChatChannelId: number
    patientActorId: number
    patientSiteGettingToLinkATranslationId?: string
    patientSiteGettingToLinkBTranslationId?: string
    patientSiteBeforeLinkATranslationId?: string
    patientSiteBeforeLinkBTranslationId?: string
    patientSiteArrivedLinkATranslationId?: string
    patientSiteArrivedLinkBTranslationId?: string
    patientSiteAboutLinkATranslationId?: string
    patientSiteAboutLinkBTranslationId?: string
    patientSiteAfterLinkATranslationId?: string
    patientSiteAfterLinkBTranslationId?: string
}


export interface AppointmentDurationsNumber {
    checkIn: number | null
    waitForIntake: number | null
    intake: number | null
    waitForExam: number | null
    exam: number | null
    waitForCheckout: number | null
    checkout: number | null
}

export enum SelectedAppointmentAction {
    CLOSED = 'CLOSED',
    SMS = 'SMS',
    ROOM_READY = 'ROOM_READY',
    APPOINTMENT_DONE = 'APPOINTMENT_DONE',
    APPOINTMENT_DETAIL = 'APPOINTMENT_DETAIL',
    MULTI = 'MULTI',
    MULTI_MESSAGE = 'MULTI_MESSAGE',
}

// export * from './AdminList.styles'
// export * from './AdminListSortSelect'
import { ListSortDirection } from '@docpace/shared-ts-utils'

export type ListSortValue = {
    title: string
    dataKey: string
    defaultSort?: ListSortDirection
    flowFuncs?: ((any: any) => any)[]
}

export const AdminTableBorderedRowStyle = ()=>{
    return <style>{`
    
    .table-scrollable-card table > thead > tr {
        border-bottom-width: 1px;
        border-style: solid;
        border-color: rgba(50,50,50,0.4) !important;
    }
    
    .table-scrollable-card table > tbody > tr {
        border-bottom-width: 1.5px;
        border-style: solid;
        border-color: rgba(50,50,50,0.2) !important;
    }

    `}</style>
}
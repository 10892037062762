export enum TranslationType {
    VWR = 'VWR',
    SMS = 'SMS',
    FAQ = 'FAQ',
    DIRECTIONS = 'DIRECTIONS',
    CONTACT = 'CONTACT',
    LINK = 'LINK',
}

export enum TranslationLanguage {
    eng = 'eng',
    spa = 'spa',
    por = 'por',
}
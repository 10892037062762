import { compact, filter, find } from 'lodash'

import { AdminDetailFragment, ManagerDetailFragment, ProviderAppointmentsCountFragment } from '@docpace/shared-graphql/fragments'

interface MakeAppointmentStatusCountInterfaceInput {
    provider: ProviderAppointmentsCountFragment
    manager: ManagerDetailFragment
    isAdminSite?: boolean
    admin?: AdminDetailFragment
}

export function makeAppointmentStatusCountToday({
    provider, manager, isAdminSite, admin
}: MakeAppointmentStatusCountInterfaceInput): null | number {
    const nodes = compact(provider?.providerDepartmentAppointmentCounts?.nodes ?? [])

    // if the user is an manager, filter by providerId and departmentId against the ProviderDepartmentPermissions, otherwise return all items
    const filteredCounts = manager ? filter(nodes, ({ providerId, departmentId })=>{
        return !!find(manager?.managerProviderPermissions?.nodes, (perm)=>{
            return perm?.providerId === providerId && (perm?.departmentId === departmentId || !perm?.departmentId)
        })
    }) : nodes

    // return the sum of the counts, depending on which site is in use
    return filteredCounts.reduce((mem, { managerAppointmentCount, appointmentCount })=>{
        return mem + (isAdminSite ? appointmentCount : managerAppointmentCount) 
    }, 0)
}
